<template>
  <div>
    <LoaderView v-if="!branding" />
    <ToasterView />
    <div class="mainView" v-if="branding">
      <HeaderView />
      <router-view class="content" v-slot="{ Component }">
        <transition name="page" mode="out-in">
          <component :is="Component" />
        </transition>
      </router-view>
    </div>
  </div>
</template>

<script>
import { mapState, mapMutations } from 'vuex'

import HeaderView from '@/components/Main/Layout/HeaderView.vue'
import ToasterView from '@/components/Toaster/ToasterView.vue'
import LoaderView from '@/components/Loaders/LoaderView.vue'

export default {
  components: {
    HeaderView,
    ToasterView,
    LoaderView,
  },
  computed: {
    ...mapState(['selectedService', 'toaster', 'branding', 'storeConfig']),
  },
  watch: {
    branding(newVal) {
      this.setFonts(newVal)
      this.setStyling(newVal)
      this.setHeadTags(newVal)
      this.addPixelScript()
    },
    storeConfig() {
      // set app name
      let appName = this.storeConfig.chain_display_name
      document.querySelector('meta[name="application-name"]').content = appName
    },
  },
  methods: {
    ...mapMutations(['SET_LOGGED_IN', 'SET_ORDER_DATA', 'SET_DAY_AND_TIME_SELECTED']),
    goToLocationsPage() {
      this.$router.push({ name: 'HomeView' })
    },
    async setFonts(newVal) {
      const fontPromises = []

      // Regular font
      const regularFont = new FontFace(
        newVal.fonts.regular_font.font_name,
        `url(${newVal.fonts.regular_font.font_url})`
      )
      fontPromises.push(regularFont.load())
      document.fonts.add(regularFont)
      document.documentElement.style.setProperty('--regular-font', `${newVal.fonts.regular_font.font_name}, sans-serif`)
      document.documentElement.style.setProperty(
        '--regular-font-size-multiplier',
        newVal.fonts.regular_font.size_multiplier || 1
      )

      // Header font
      const headerFont = new FontFace(newVal.fonts.header_font.font_name, `url(${newVal.fonts.header_font.font_url})`)
      fontPromises.push(headerFont.load())
      document.fonts.add(headerFont)
      document.documentElement.style.setProperty('--header-font', `${newVal.fonts.header_font.font_name}, sans-serif`)
      document.documentElement.style.setProperty(
        '--header-font-size-multiplier',
        newVal.fonts.header_font.size_multiplier || 1
      )

      // Regular bold font
      const regularBoldFont = new FontFace(
        newVal.fonts.regular_bold_font.font_name,
        `url(${newVal.fonts.regular_bold_font.font_url})`
      )
      fontPromises.push(regularBoldFont.load())
      document.fonts.add(regularBoldFont)
      document.documentElement.style.setProperty(
        '--regular-bold-font',
        `${newVal.fonts.regular_bold_font.font_name}, sans-serif`
      )
      document.documentElement.style.setProperty(
        '--regular-bold-font-size-multiplier',
        newVal.fonts.regular_bold_font.size_multiplier || 1
      )

      // Button font
      const buttonFontName = newVal.fonts.button_font
        ? newVal.fonts.button_font.font_name
        : newVal.fonts.header_font.font_name
      const buttonFontUrl = newVal.fonts.button_font
        ? newVal.fonts.button_font.font_url
        : newVal.fonts.header_font.font_url
      const buttonFont = new FontFace(buttonFontName, `url(${buttonFontUrl})`)
      fontPromises.push(buttonFont.load())
      document.fonts.add(buttonFont)
      document.documentElement.style.setProperty('--button-font', `${buttonFontName}, sans-serif`)
      document.documentElement.style.setProperty(
        '--button-font-size-multiplier',
        newVal.fonts.button_font?.size_multiplier || 1
      )

      // Wait for all fonts to load
      await Promise.all(fontPromises)
    },
    setStyling(newVal) {
      // set primary color as a css variable
      let primaryColor = newVal.primary_color
      document.documentElement.style.setProperty('--primary-color', primaryColor)

      // set primary text color as a css variable
      let primaryTextColor = newVal.primary_text_color
      document.documentElement.style.setProperty('--primary-text-color', primaryTextColor)

      // set background color as a css variable
      let backgroundColor = newVal.background_color
      document.documentElement.style.setProperty('--background-color', backgroundColor)

      // set border color as a css variable
      let borderColor = this.branding.dark_mode_enabled ? '#3b3b3b' : '#e6e3e3'
      document.documentElement.style.setProperty('--border-color', borderColor)

      // set passive color as a css variable
      let passiveColor = newVal.passive_color ? newVal.passive_color : '#8f8f8f'
      document.documentElement.style.setProperty('--passive-color', passiveColor)

      // set gray color as a css variable
      let grayColor = newVal.gray_color ? newVal.gray_color : '#EFEFEF'
      document.documentElement.style.setProperty('--gray-color', grayColor)

      // set text color as a css variable
      let textColor = this.branding.dark_mode_enabled ? '#ffffff' : newVal.fonts.regular_font.font_color
      document.documentElement.style.setProperty('--text-color', textColor)

      // set button radius as a css variable
      let buttonRadius = newVal.display_options?.button_radius?.enabled
        ? newVal.display_options.button_radius.radius + 'px'
        : '5px'
      document.documentElement.style.setProperty('--button-radius', buttonRadius)

      // set header text color as a css variable
      let headerTextColor = this.branding.dark_mode_enabled ? '#ffffff' : newVal.fonts.header_font.font_color
      document.documentElement.style.setProperty('--header-text-color', headerTextColor)

      // set custom green color as a css variable
      let customGreenColor = newVal.display_options?.custom_green_color
        ? newVal.display_options.custom_green_color
        : '#17873e'
      document.documentElement.style.setProperty('--custom-green-color', customGreenColor)
    },
    setHeadTags(newVal) {
      // set favicon
      let favicon = newVal.favicon_url
      document.querySelector('link[rel="icon"]').href = favicon

      // set document title
      let title = newVal.title
      document.title = title
      localStorage.setItem('title', title)

      // set meta description
      let metaDescription = newVal.description
      document.querySelector('meta[name="description"]').content = metaDescription

      // set theme color
      let themeColor = newVal.background_color
      document.querySelector('meta[name="theme-color"]').content = themeColor

      // set og image
      let ogImage = newVal.logo_url
      document.querySelector('meta[property="og:image"]').content = ogImage
    },
    setInnerHeight() {
      // set innerHeight as a css variable
      const innerHeight = window.innerHeight
      document.documentElement.style.setProperty('--innerHeight', `${innerHeight}px`)

      // set innerHeight as a css variable on resize
      window.addEventListener('resize', () => {
        const innerHeight = window.innerHeight
        document.documentElement.style.setProperty('--innerHeight', `${innerHeight}px`)
      })

      // set innerHeight as a css variable on screen orientation change
      window.addEventListener('orientationchange', () => {
        const innerHeight = window.innerHeight
        document.documentElement.style.setProperty('--innerHeight', `${innerHeight}px`)
      })
    },
    preventScrollWhenModalOpen() {
      // if .modalContainer is in the DOM, add overflow hidden to body, if not add overflow unset.
      let modalContainer = document.querySelector('.modalContainer')
      if (modalContainer) {
        document.body.style.overflow = 'hidden'
      } else {
        document.body.style.overflow = 'unset'
      }

      // if .modalContainer is added to the DOM, add overflow hidden to body, if not add overflow unset.
      document.addEventListener(
        'DOMNodeInserted',
        () => {
          let modalContainer = document.querySelector('.modalContainer')
          if (modalContainer) {
            document.body.style.overflow = 'hidden'
          } else {
            document.body.style.overflow = 'unset'
          }
        },
        false
      )
    },
    checkVersion() {
      let version = '1.2.06'
      // if version is not same as local storage version, clear local storage
      if (localStorage.getItem('version') !== version && localStorage.getItem('version') !== null) {
        this.SET_LOGGED_IN(false)
        this.SET_ORDER_DATA(null)
        localStorage.clear()
        localStorage.setItem('version', version)
        location.reload()
      } else if (localStorage.getItem('version') === null) {
        localStorage.setItem('version', version)
      }
    },
    checkDiscountCode() {
      // if discount code is in query string, save it to local storage to use when creating order
      if (this.$route.query.discount_code) {
        localStorage.removeItem('orderId')
        localStorage.removeItem('dayAndTimeSelected')
        this.SET_DAY_AND_TIME_SELECTED(false)
        this.SET_ORDER_DATA(null)
        localStorage.setItem('discountCode', this.$route.query.discount_code)
      }
    },
    checkItemId() {
      // if menu item id is in query string, save it to local storage
      if (this.$route.query.menu_item_id) {
        localStorage.setItem('deepLinkItemId', this.$route.query.menu_item_id)
      }
    },
    addPixelScript() {
      const pixelId = this.branding?.marketing_ids.meta_pixel_identifier

      if (pixelId) {
        !(function (f, b, e, v, n, t, s) {
          if (f.fbq) return
          n = f.fbq = function () {
            n.callMethod ? n.callMethod.apply(n, arguments) : n.queue.push(arguments)
          }
          if (!f._fbq) f._fbq = n
          n.push = n
          n.loaded = !0
          n.version = '2.0'
          n.queue = []
          t = b.createElement(e)
          t.async = !0
          t.src = v
          s = b.getElementsByTagName(e)[0]
          s.parentNode.insertBefore(t, s)
        })(window, document, 'script', 'https://connect.facebook.net/en_US/fbevents.js')

        fbq('init', pixelId)
        fbq('track', 'PageView')
      }
    },
  },
  async created() {
    await this.$store.dispatch('getBranding')
    this.$store.dispatch('getStoreConfig')
    if (this.$route.name !== 'OrderQuoteView') {
      this.$store.dispatch('getOrderData')
      this.$store.dispatch('getCustomerData')
    }

    if (localStorage.getItem('selectedStoreId')) {
      this.$store.dispatch('getSelectedStore')
    }
    this.checkVersion()
    this.setInnerHeight()
    this.preventScrollWhenModalOpen()
    this.checkDiscountCode()
    this.checkItemId()
  },
  mounted() {
    this.addPixelScript()
  },
}
</script>

<style src="./assets/css/App.css"></style>
